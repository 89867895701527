<!-- 表单设置 -->
<template>
  <div>
    <blockTitle :title="pageText.headerTitle">
      <template #customContent>
        <el-button class="ml-4" type="primary" size="mini" @click="submit">保存
        </el-button>
      </template>
    </blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <div class="content flex flex-row divide-x">
          <div class="left-box w-6/12 p-4">
            <div
              class="title font-bold text-sm flex align-items-center justify-between"
            >
              申请人资料
              <span @click="pushTextFormList" class="text-xs cursor-pointer">
                <i class="el-icon-circle-plus-outline"></i> 新增表单项</span>
            </div>
            <div class="people-box mt-2">
              <div v-for="(item, index) in textFormList" :key="index">
                <div
                  class="option-box flex flex-row bg-gray-100 py-1 px-2 justify-end"
                >
                  <div
                    class="text-xs border py-1 px-2 cursor-pointer"
                    @click="removeTextFormList(index)"
                  >
                    删除<i class="ml-1 text-red-500 el-icon-delete-solid"></i>
                  </div>
                  <div
                    class="text-xs border py-1 px-2 cursor-pointer"
                    v-if="index !== 0"
                    @click="changePosition(index, 1)"
                  >
                    上移动<i class="ml-1 el-icon-caret-top"></i>
                  </div>
                  <div
                    class="text-xs border py-1 px-2 cursor-pointer"
                    v-if="index !== textFormList.length - 1"
                    @click="changePosition(index, 2)"
                  >
                    下移动<i class="ml-1 el-icon-caret-bottom"></i>
                  </div>
                </div>
                <el-form
                  class="mb-4 p-4 border"
                  label-position="left"
                  label-width="120px"
                  size="small"
                >
                  <el-form-item label="表单项标题">
                    <el-input v-model="item.name"></el-input>
                  </el-form-item>
                  <el-form-item label="表单项类型">
                    <el-radio-group v-model="item.formType">
                      <el-radio-button :label="1">输入项</el-radio-button>
                      <el-radio-button :label="2">选项</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="文本类型" v-if="item.formType === 1">
                    <el-radio-group v-model="item.textType">
                      <el-radio-button :label="1">单行文本</el-radio-button>
                      <el-radio-button :label="2">多行文本</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="选项内容" v-if="item.formType === 2">
                    <template
                      v-for="(optionItem, optionIndex) in item.optionList"
                    >
                      <div
                        :key="optionIndex"
                        class="mb-4 flex flex-row align-items-center justify-between"
                      >
                        <el-input
                          class="w-11/12"
                          v-model="optionItem.name"
                        ></el-input>
                        <i
                          @click="removeOption(index, optionIndex)"
                          class="el-icon-remove-outline text-lg cursor-pointer"
                        ></i>
                      </div>
                    </template>
                    <div class="cursor-pointer" @click="addOption(index)">
                      <i class="el-icon-circle-plus-outline mr-1"></i
                      >添加选项内容
                    </div>
                  </el-form-item>
                  <el-form-item label="是否必填项">
                    <el-checkbox
                      v-model="item.isRequired"
                      :true-label="1"
                      :false-label="2"
                    >必填项
                    </el-checkbox>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div class="right-box w-6/12 p-4" v-if="category === 1">
            <div class="title font-bold text-sm">试用产品资料</div>
            <div class="device-box mt-2">
              <div class="device-type flex flex-row align-items-center">
                <div class="device-type-item text-sm">设备类型：</div>
                <div
                  class="device-type-item text-sm border px-4 py-2 mx-2 cursor-pointer"
                  v-for="(item, index) in sysDeviceTypeList"
                  :key="index"
                  @click="selectDeviceType(item)"
                  :class="{
                    'is-select-device-type':
                      curDeviceType.deviceType === item.deviceType
                  }"
                >
                  {{ item.deviceTypeName }}
                </div>
              </div>
              <div
                class="device-model flex flex-row align-items-center my-4"
                v-if="curDeviceType.deviceType"
              >
                <div class="device-model-item text-sm">设备型号：</div>
                <div
                  class="device-model-item text-sm border px-4 py-2 mx-2 cursor-pointer"
                  v-for="(item, index) in deviceTypeList"
                  :key="index"
                  @click="selectDeviceModel(item)"
                  :class="{
                    'is-select-device-type':
                      curDeviceModel.deviceModel === item.deviceModel
                  }"
                >
                  {{ item.deviceModel }}
                </div>
              </div>
              <div
                @click="pushDeviceFormList"
                class="text-xs cursor-pointer my-4"
                v-if="curDeviceModel.deviceModel"
              >
                <i class="el-icon-circle-plus-outline"></i> 新增表单项
              </div>
              <template v-for="(item, index) in deviceFormList">
                <div
                  :key="index"
                  v-if="item.deviceModel === curDeviceModel.deviceModel"
                >
                  <div
                    class="option-box flex flex-row bg-gray-100 py-1 px-2 justify-end"
                  >
                    <div
                      class="text-xs border py-1 px-2 cursor-pointer"
                      @click="removeDeviceFormList(index)"
                    >
                      删除<i class="ml-1 text-red-500 el-icon-delete-solid"></i>
                    </div>
                    <div
                      class="text-xs border py-1 px-2 cursor-pointer"
                      @click="changeDevicePosition(index, 1)"
                    >
                      上移动<i class="ml-1 el-icon-caret-top"></i>
                    </div>
                    <div
                      class="text-xs border py-1 px-2 cursor-pointer"
                      v-if="index !== deviceFormList.length - 1"
                      @click="changeDevicePosition(index, 2)"
                    >
                      下移动<i class="ml-1 el-icon-caret-bottom"></i>
                    </div>
                  </div>
                  <el-form
                    class="mb-4 p-4 border"
                    label-position="left"
                    label-width="120px"
                    size="small"
                  >
                    <el-form-item label="表单项标题">
                      <el-input v-model="item.name"></el-input>
                    </el-form-item>
                    <!--                    <el-form-item label="表单项类型">-->
                    <!--                      <el-radio-group v-model="item.formType">-->
                    <!--                        <el-radio-button :label="1">输入项</el-radio-button>-->
                    <!--                        <el-radio-button :label="2">选项</el-radio-button>-->
                    <!--                      </el-radio-group>-->
                    <!--                    </el-form-item>-->
                    <el-form-item label="选项内容" v-if="item.formType === 2">
                      <template
                        v-for="(optionItem, optionIndex) in item.optionList"
                      >
                        <div
                          :key="optionIndex"
                          class="mb-4 flex flex-row align-items-center justify-between"
                        >
                          <el-input
                            class="w-11/12"
                            v-model="optionItem.name"
                          ></el-input>
                          <i
                            @click="removeDeviceOption(index, optionIndex)"
                            class="el-icon-remove-outline text-lg cursor-pointer"
                          ></i>
                        </div>
                      </template>
                      <div
                        class="cursor-pointer"
                        @click="addDeviceOption(index)"
                      >
                        <i class="el-icon-circle-plus-outline mr-1"></i
                        >添加选项内容
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.is-select-device-type {
  color: #234883;
  font-weight: bold;
  border: 1px solid #234883;
}
</style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      category: '',
      textFormObj: {},
      textFormList: [],
      deviceFormList: [],
      sysDeviceTypeList: [],
      deviceTypeList: [],
      curDeviceType: {},
      curDeviceModel: {},
      deviceFormArr: {}
    }
  },
  computed: {
    pageText: function () {
      const category = this.category
      const obj = {
        headerTitle: ''
      }
      switch (category) {
        case 1:
          obj.headerTitle = '试用表单设置'
          break
        case 2:
          obj.headerTitle = '加盟申请表单设置'
          break
      }
      return obj
    }
  },
  methods: {
    getData () {
      this.$api.sysForm.select({
        category: this.category
      }).then(res => {
        this.textFormList = res.data.textFormList
        this.deviceFormList = res.data.deviceFormList
      })
    },
    addOption (index) {
      if (this.textFormList[index].optionList) {
        this.textFormList[index].optionList.push({ name: '' })
      } else {
        this.$set(this.textFormList[index], 'optionList', [{ name: '' }])
      }
    },
    addDeviceOption (index) {
      if (this.deviceFormList[index].optionList) {
        this.deviceFormList[index].optionList.push({ name: '' })
      } else {
        this.$set(this.deviceFormList[index], 'optionList', [{ name: '' }])
      }
    },
    removeOption (index, optionIndex) {
      this.textFormList[index].optionList.splice(optionIndex, 1)
    },
    removeDeviceOption (index, optionIndex) {
      this.deviceFormList[index].optionList.splice(optionIndex, 1)
    },
    changePosition (index, direction) {
      // direction 1.上移 2.下移
      if (direction === 1) {
        const cur = JSON.parse(JSON.stringify(this.textFormList[index]))
        const changeItem = JSON.parse(JSON.stringify(this.textFormList[index - 1]))
        const tmp = cur
        this.$set(this.textFormList, index, changeItem)
        this.$set(this.textFormList, index - 1, tmp)
      } else if (direction === 2) {
        const cur = JSON.parse(JSON.stringify(this.textFormList[index]))
        const changeItem = JSON.parse(JSON.stringify(this.textFormList[index + 1]))
        const tmp = cur
        this.$set(this.textFormList, index, changeItem)
        this.$set(this.textFormList, index + 1, tmp)
      }
    },
    changeDevicePosition (index, direction) {
      // direction 1.上移 2.下移
      if (direction === 1) {
        const cur = JSON.parse(JSON.stringify(this.deviceFormList[index]))
        const changeItem = JSON.parse(JSON.stringify(this.deviceFormList[index - 1]))
        const tmp = cur
        this.$set(this.deviceFormList, index, changeItem)
        this.$set(this.deviceFormList, index - 1, tmp)
      } else if (direction === 2) {
        const cur = JSON.parse(JSON.stringify(this.deviceFormList[index]))
        const changeItem = JSON.parse(JSON.stringify(this.deviceFormList[index + 1]))
        const tmp = cur
        this.$set(this.deviceFormList, index, changeItem)
        this.$set(this.deviceFormList, index + 1, tmp)
      }
    },
    pushTextFormList () {
      this.textFormList.push({
        category: this.category,
        name: '',
        formType: 1,
        textType: 1,
        type: 1
      })
    },
    pushDeviceFormList () {
      this.deviceFormList.push({
        category: this.category,
        name: '',
        formType: 2,
        deviceModel: this.curDeviceModel.deviceModel,
        optionList: [],
        type: 2
      })
    },
    removeTextFormList (index) {
      this.textFormList.splice(index, 1)
    },
    removeDeviceFormList (index) {
      this.deviceFormList.splice(index, 1)
    },
    getSysDeviceType () {
      this.$api.sysForm.sysDeviceTypeFindAll().then(res => {
        this.sysDeviceTypeList = res.data
      })
    },
    selectDeviceType (deviceType) {
      this.curDeviceType = deviceType
      this.curDeviceModel = ''
      this.sysDeviceDeviceFindAll(deviceType)
    },
    sysDeviceDeviceFindAll (deviceType) {
      this.$api.sysForm.sysDeviceDeviceFindAll({ deviceType: deviceType.deviceType }).then(res => {
        this.deviceTypeList = res.data
      })
    },
    selectDeviceModel (deviceModel) {
      this.curDeviceModel = deviceModel
    },
    submit () {
      if (!this.category) {
        this.$message.error('category 参数错误')
        return false
      }
      this.$api.sysForm.insertBatch({
        textFormList: this.textFormList,
        deviceFormList: this.deviceFormList
      }).then(res => {
        this.$message.success('保存成功 ')
      })
    }
  },
  mounted () {
    this.category = parseInt(this.$route.query.category)
    this.getData()
    this.getSysDeviceType()
  },
  beforeRouteUpdate (to, from, next) {
    this.category = parseInt(to.query.category)
    this.getData()
    next()
  }
}
</script>
